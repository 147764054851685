import axios from "axios"
import { useEffect, useState } from "react"
import { Table } from "../../components/Table"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"
import { DeleteModal, Modal } from "../../components/Modal"
import Stock from "./Stock"
import Product from "./Product"
import Upload from "./Upload"
import useApp from "../../hooks/useApp"

const ProductsPage = ({ type }) => {
  const [pagination, setPagination] = useState({})
  const [viewStock, setViewStock] = useState(false)
  const [viewProduct, setViewProduct] = useState(false)
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [deletion, setDeletion] = useState(false)
  const [upload, setUpload] = useState(false)
  const [search, setSearch] = useState("")
  const [data, setData] = useState([])
  const [uploads, setUploads] = useState([])
  const [viewUploads, setViewUploads] = useState(false)
  const { permissions } = useApp()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      getSearch()
    }, 1000)
    return () => clearTimeout(getData)
  }, [search])

  const getData = async (params = {}) => {
    const { token } = await getStorage()
    setLoading(true)
    const options = {
      method: "GET",
      url: PRODUCTS_API + "/products",
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
        type,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        if (res.data) {
          const { pagination, results } = res.data
          setData(results)
          setPagination(pagination)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handleDelete = async () => {
    const { token } = await getStorage()
    const options = {
      url: `${PRODUCTS_API}/product/${selected.id}`,
      method: "DELETE",
      headers: {
        authorization: "Bearer " + token,
      },
    }
    setDeletion(true)

    axios(options)
      .then((res) => {
        setDeletion(false)
        if (res.data) {
          toastMessage("success", "Product deleted successfully.")
          setSelected(null)
          setDeleting(false)
          getData()
        }
      })
      .catch((err) => {
        setDeletion(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handleDeleteModal = async (product) => {
    await setSelected(product)
    setDeleting(true)
  }

  const handlePagination = (page) => {
    if (!page) return
    getData({ pageNumber: page })
  }

  function getSearch() {
    if (search === "") return
    return getData({ search })
  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    if (value === "") {
      return getData()
    }
  }

  const handleViewStock = async (product) => {
    await setSelected(product)
    setViewStock(true)
  }
  const handleViewProduct = async (product) => {
    await setSelected(product)
    setViewProduct(true)
  }

  const headers = [
    { title: "Part Number", key: "name" },
    { title: "Brand", key: "brandName" },
    { title: "Category", key: "categoryName" },
    { title: "Sub Category", key: "subCategoryName" },
    { title: "Type", key: "typeName" },
    { title: "Image", key: "image", isLink: true },
    { title: "Description", key: "description" },
    { title: "Cross Reference", key: "crossReference" },
    { title: "Additional Info", key: "additionalInfo" },
    {
      title: "Date Inserted",
      key: "createdAt",
      isMoment: true,
      formatTime: "lll",
    },
  ]
  if (permissions && permissions.includes("manage-hcv-parts")) {
    headers.push({ title: "Action", key: "action" })
  }
  let filters = []
  if (!type && permissions && permissions.includes("manage-hcv-parts")) {
    filters.push({
      title: "Upload Products",
      icon: "bx bx-upload",
      onPress: () => setUpload(true),
    })
  }
  return (
    <>
      <div className="card">
        <div className="card-body">
          <Table
            isLoading={loading}
            data={data}
            showAdd={
              !type && permissions && permissions.includes("manage-hcv-parts")
            }
            handlePagination={handlePagination}
            pagination={pagination}
            addButtonText="Add Product"
            search_text={search}
            handleSearch={handleSearch}
            handleAddPressed={() => handleViewProduct(null)}
            placeholder={"Search part number"}
            headers={headers}
            filters={filters}
            actions={[
              {
                name: "Stock",
                onPress: (row) => handleViewStock(row),
              },
              {
                name: "Edit",
                onPress: (row) => handleViewProduct(row),
              },
              {
                name: "Delete",
                onPress: (row) => handleDeleteModal(row),
              },
            ]}
          />
        </div>
      </div>
      <Modal
        title="Product Stock"
        show={viewStock}
        handleClose={() => (setViewStock(false), setSelected(null))}
      >
        <Stock
          product={selected}
          handleClose={() => (setViewStock(false), setSelected(null))}
        />
      </Modal>
      <Modal
        show={upload}
        title="Upload Products"
        handleClose={() => setUpload(false)}
      >
        <Upload handleClose={() => setUpload(false)} />
      </Modal>
      <Modal
        title={selected ? "Edit Product" : "Add Product"}
        show={viewProduct}
        size="lg"
        handleClose={() => (setViewProduct(false), setSelected(null))}
      >
        <Product
          selected={selected}
          getData={getData}
          handleClose={() => (setViewProduct(false), setSelected(null))}
        />
      </Modal>

      <Modal
        show={deleting}
        title={`Delete Product`}
        handleClose={() => (setDeleting(false), setSelected(null))}
      >
        <DeleteModal
          name={selected && selected.name}
          handleClose={() => (setDeleting(false), setSelected(null))}
          isSubmitting={deletion}
          onDelete={() => handleDelete()}
        />
      </Modal>
    </>
  )
}

export default ProductsPage
