import { useEffect, useState } from "react"
import { Table } from "../components/Table"
import { PRODUCTS_API } from "../constants/api"
import { getStorage } from "../utils/storage"
import axios from "axios"
import toastMessage from "../utils/toastMessage"
import { Modal } from "../components/Modal"
import AddBrand from "../features/Brands/Add"
import DeleteBrand from "../features/Brands/Delete"
import useApp from "../hooks/useApp"

export default function BrandsPage() {
  const [loading, setLoading] = useState(true)
  const [viewModal, setViewModal] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [selected, setSelected] = useState()
  const [search, setSearch] = useState("")
  const { permissions } = useApp()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let gd = null
    if (search) {
      gd = setTimeout(() => {
        getData({ search })
      }, 1000)
    }
    return () => clearTimeout(gd)
  }, [search])

  async function getData(params = {}) {
    const { token } = await getStorage()
    setLoading(true)
    const options = {
      method: "GET",
      url: PRODUCTS_API + "/brands",
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        if (res.data) {
          const { pagination, results } = res.data

          setData(results)
          setPagination(pagination)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handlePagination = (page) => {
    if (!page) return
    getData({ pageNumber: page })
  }
  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    if (value === "") {
      return getData()
    }
  }
  const headers = [
    { title: "Name", key: "name" },
    { title: "Code", key: "code" },
    { title: "Country", key: "country" },
    { title: "Description", key: "description" },
    {
      title: "Date Created",
      key: "createdAt",
      isMoment: true,
      formatTime: "lll",
    },
  ]
  if (permissions && permissions.includes("manage-banners")) {
    headers.push({ title: "Action", key: "action" })
  }
  return (
    <>
      <div className="card">
        <div className="card-body">
          <Table
            isLoading={loading}
            data={data}
            showAdd={permissions && permissions.includes("manage-banners")}
            pagination={pagination}
            search_text={search}
            placeholder={"Search by name"}
            handleSearch={handleSearch}
            handlePagination={handlePagination}
            addButtonText="Add Brand"
            handleAddPressed={() => setViewModal(true)}
            headers={headers}
            actions={[
              {
                name: "Edit",
                onPress: async (row) => {
                  await setSelected(row)
                  setViewModal(true)
                },
              },
              {
                name: "Delete",
                onPress: async (row) => {
                  await setSelected(row)
                  setDeleteModal(true)
                },
              },
            ]}
          />
        </div>
      </div>
      <Modal
        show={viewModal}
        title={`${selected ? "Edit" : "Add"} Brand`} //+ selected ? `: ${selected.firstName} ${selected.lastName}`: ''}
        handleClose={() => {
          setSelected(null)
          setViewModal(false)
        }}
      >
        <AddBrand
          selected={selected}
          getData={getData}
          handleClose={() => {
            setSelected(null)
            setViewModal(false)
          }}
        />
      </Modal>

      <Modal
        show={deleteModal}
        title={"Delete Brand"}
        handleClose={() => {
          setSelected(null)
          setDeleteModal(false)
        }}
      >
        <DeleteBrand
          handleClose={() => {
            setSelected(null)
            setDeleteModal(false)
          }}
          getData={getData}
          brand={selected}
        />
      </Modal>
    </>
  )
}
