import axios from "axios"
import React, { useState } from "react"
import { useEffect } from "react"
import { DeleteModal, Modal } from "../components/Modal"
import { Table } from "../components/Table"
import { USERS_API } from "../constants/api"
import User from "../features/User/User"
import { debounce } from "../utils/debounce"
import { clearStorage, getStorage } from "../utils/storage"
import toastMessage from "../utils/toastMessage"
import PermissionsManagement from "../features/User/PermissionsManagement"
import { Spinner } from "react-bootstrap"
import useApp from "../hooks/useApp"

let copyUsers = []

const UsersPage = () => {
  const [pagination, setPagination] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const [edit, setEdit] = useState(false)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [search, setSearch] = useState("")
  const [sending, setSending] = useState(false)
  const [showPerm, setShowPerm] = useState(false)
  const { permissions } = useApp()

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      getSearch()
    }, 1000)
    return () => clearTimeout(getData)
  }, [search])

  async function getUsers(params) {
    const { token } = await getStorage()
    setLoading(true)

    const options = {
      method: "GET",
      url: USERS_API + "/users",
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        if (res.data) {
          const { pagination, results } = res.data

          setUsers(results)
          copyUsers = results
          setPagination(pagination)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handleView = (client) => {
    setViewModal(true)
    setSelected(client)
    setEdit(false)
  }

  const handleEdit = (client) => {
    setViewModal(true)
    setSelected(client)
    setEdit(true)
  }

  const handleAdd = () => {
    setViewModal(true)
    setSelected(null)
    setEdit(false)
  }

  const handleCloseModal = () => {
    setViewModal(false)
    setDeleteModal(false)
    setSelected(null)
    setEdit(false)
  }

  const handleDelete = (user) => {
    setSelected(user)
    setDeleteModal(true)
  }

  const handlePagination = (page) => {
    if (!page) return
    getUsers({ pageNumber: page })
  }

  function getSearch() {
    if (search === "") return
    return getUsers({ search })
  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    if (value === "") {
      return getUsers()
    }
  }

  const onDelete = async () => {
    const { id, token } = await getStorage()
    setDeleting(true)

    const options = {
      method: "DELETE",
      url: USERS_API + "/user/" + selected.id,
      headers: {
        authorization: "Bearer " + token,
      },
    }

    axios(options)
      .then(async (res) => {
        setDeleting(false)
        if (res.status === 200) {
          if (id === selected.id) {
            await clearStorage()
            toastMessage(
              "success",
              "Your account has been deleted successfully"
            )

            setTimeout(() => {
              window.location.href = "/login"
            }, 2000)
            return
          }
          handleCloseModal()
          getUsers()
          toastMessage("success", "User deleted successfully")
        }
      })
      .catch((err) => {
        setDeleting(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  async function sendInvite(user) {
    if (!user || !user.email) return
    const options = {
      url: `${USERS_API}/reset-password`,
      method: "GET",
      params: {
        email: user.email,
      },
    }
    setSending(true)
    axios(options)
      .then((res) => {
        if (res.data) {
          setSending(false)
          toastMessage("success", "Account activation email sent successfully.")
        }
      })
      .catch((err) => {
        setSending(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const headers = [
    { title: "First Name", key: "firstName" },
    { title: "Last Name", key: "lastName" },
    { title: "Email", key: "email" },
    { title: "Phone", key: "phone" },
    // { title: "Status", key: "status" },
    {
      title: "Date of Registration",
      key: "createdAt",
      isMoment: true,
      formatTime: "lll",
    },
  ]

  if (permissions && permissions.includes("manage-users")) {
    headers.push({ title: "Action", key: "action" })
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Table
            isLoading={loading}
            data={users}
            showAdd={permissions && permissions.includes("manage-users")}
            pagination={pagination}
            handlePagination={handlePagination}
            addButtonText="Add User"
            search_text={search}
            handleSearch={handleSearch}
            handleAddPressed={handleAdd}
            placeholder={"Search by name or email or phone"}
            headers={headers}
            rowPress={(row) => handleView(row)}
            actions={[
              // {
              //   name: "View",
              //   onPress: (row)=> handleView(row),
              // },
              {
                name: "Edit",
                onPress: (row) => handleEdit(row),
              },
              {
                name: "Delete",
                onPress: (row) => handleDelete(row),
              },
              {
                name: sending ? (
                  <div className="py-2 px-2">
                    <Spinner
                      variant={"success"}
                      animation={"border"}
                      size={"sm"}
                    />
                  </div>
                ) : (
                  "Send Invite"
                ),
                onPress: (row) => sendInvite(row),
              },
              {
                name: "Permissions",
                onPress: (row) => {
                  setSelected(row)
                  setShowPerm(true)
                },
              },
            ]}
          />
        </div>
      </div>

      <Modal
        show={viewModal}
        title={"User Management"}
        handleClose={handleCloseModal}
      >
        <User
          edit={edit}
          selected={selected}
          handleClose={handleCloseModal}
          getUsers={getUsers}
        />
      </Modal>

      <Modal
        show={deleteModal}
        title={`Delete user`}
        handleClose={handleCloseModal}
      >
        <DeleteModal
          name={selected && selected.firstName + " " + selected.lastName}
          handleClose={handleCloseModal}
          isSubmitting={deleting}
          onDelete={onDelete}
        />
      </Modal>

      <Modal
        show={showPerm}
        size={"lg"}
        title={`${
          selected && selected.firstName + " " + selected.lastName
        } Permissions`}
        handleClose={() => {
          setSelected()
          setShowPerm(false)
        }}
      >
        <PermissionsManagement
          user={selected}
          handleClose={() => {
            setSelected(null)
            setShowPerm(false)
          }}
        />
      </Modal>
    </div>
  )
}

export default UsersPage
