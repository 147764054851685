import React, { useEffect, useState } from "react"
import { Button } from "../../components/Button"
import { Input, Select, TextArea } from "../../components/Input"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"
import axios from "axios"

const Product = ({ selected, getData, handleClose }) => {
  const defaultProduct = {
    name: "",
    subCategoryId: "",
    categoryId: "",
    type_id: "",
    brand_id: "",
    crossReference: "",
    description: "",
    additionalInfo: "",
    tag: "",
    image: "",
  }
  const [product, setProduct] = useState(defaultProduct)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const [submittting, setSubmitting] = useState(false)
  const [fetching, setFetching] = useState(true)

  const [categories, setcategories] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [types, setTypes] = useState([])
  const [brands, setBrands] = useState([])

  const [fetchingBrands, setFetchingBrands] = useState(true)

  useEffect(() => {
    const init = async () => {
      if (selected) {
        let passed = { ...selected }
        passed.subCategoryId = {
          label: selected.subCategoryName,
          value: selected.subCategoryId,
          id: selected.subCategoryId,
        }
        passed.categoryId = {
          label: selected.categoryName,
          value: selected.categoryId,
          id: selected.categoryId,
        }
        passed.type_id = {
          label: selected.typeName,
          value: selected.type_id,
        }
        passed.brand_id = {
          label: selected.brandName,
          value: selected.brandId,
        }
        await setProduct(passed)
      }
    }
    init()
    getCategories()
    getBrands()
  }, [])

  const getCategories = async () => {
    const { token } = await getStorage()
    const options = {
      url: PRODUCTS_API + "/categories",
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
      },
    }
    axios(options)
      .then(async (res) => {
        setFetching(false)
        if (res.data) {
          const { results } = res.data
          if (results && Array.isArray(results)) {
            let data = []
            await results.forEach((r) => {
              r.label = r.name
              r.value = r.id
              data.push(r)
            })
            setcategories(data)
          }
        }
      })
      .catch((err) => {
        setFetching(false)
        console.log("Err: ", err)
      })
  }

  const getSubCategories = async (id) => {
    setFetching(true)
    const { token } = await getStorage()
    const options = {
      url: `${PRODUCTS_API}/categories/sub/${id}`,
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
      },
    }

    axios(options)
      .then(async (res) => {
        setFetching(false)
        if (res.data) {
          const { results } = res.data
          if (results && Array.isArray(results)) {
            let data = []
            await results.forEach((r) => {
              r.label = r.name
              r.value = r.id
              data.push(r)
            })
            setSubcategories(data)
          }
        }
      })
      .catch((err) => {
        setFetching(false)
        console.log("Err: ", err)
      })
  }

  const getTypes = async (subcategory) => {
    setLoading(true)
    const { token } = await getStorage()
    const options = {
      url: `${PRODUCTS_API}/product/types/${subcategory}`,
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
      },
    }
    axios(options)
      .then(async (res) => {
        setLoading(false)
        if (res.data) {
          const { results } = res.data
          if (results && Array.isArray(results)) {
            let data = []
            await results.forEach((r) => {
              r.label = r.name
              r.value = r.id
              data.push(r)
            })
            setTypes(data)
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("Err: ", err)
      })
  }

  async function getBrands(params = {}) {
    const { token } = await getStorage()
    setLoading(true)
    const options = {
      method: "GET",
      url: PRODUCTS_API + "/brands",
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
        pageLimit: 200,
      },
    }
    axios(options)
      .then(async (res) => {
        if (res.data) {
          const { results } = res.data
          if (results && Array.isArray(results)) {
            let data = []
            await results.forEach((r) => {
              r.label = r.name
              r.value = r.id
              data.push(r)
            })
            setBrands(data)
          }
        }
        setFetchingBrands(false)
      })
      .catch((err) => {
        setFetchingBrands(false)
      })
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    if (error[name]) {
      delete error[name]
    }
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSelect = (value, { name }) => {
    if (error[name]) {
      delete error[name]
    }
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }))
    if (name === "categoryId") {
      getSubCategories(value.id)
    }
    if (name === "subCategoryId") {
      getTypes(value.value)
    }
  }

  const handleFileInput = (e) => {
    if (error.image) {
      delete error.image
    }
    setProduct((prev) => ({
      ...prev,
      image: e.target.files[0],
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let isValid = await validateProduct()

    if (!isValid) return
    const {
      name,
      additionalInfo,
      crossReference,
      tag,
      type_id,
      brand_id,
      description,
      image,
    } = product

    const form = new FormData()

    form.append("name", name)
    form.append("type_id", type_id.value)
    form.append("brand_id", brand_id.value)
    form.append("description", description)

    form.append("additionalInfo", additionalInfo)

    form.append("crossReference", crossReference)

    form.append("img", image)

    setSubmitting(true)
    const { token } = await getStorage()
    const options = {
      url: `${PRODUCTS_API}/${
        selected ? `product/${selected.id}` : "products"
      }`,
      method: selected ? "PUT" : "POST",
      headers: {
        authorization: "Bearer " + token,
      },
      data: form,
    }

    axios(options)
      .then((res) => {
        setSubmitting(false)
        if (res.data) {
          toastMessage(
            "success",
            `Product ${
              selected && selected.id ? "updated" : "added"
            } successfully`
          )
          handleClose()
          // setSelectedProduct(res.data)
          // setActive("stock")
          // setTimeout(()=> setActive(null), 500)
          getData()
        }
      })
      .catch((err) => {
        setSubmitting(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  async function validateProduct() {
    let err = {},
      valid = true
    const {
      name,
      subCategoryId,
      categoryId,
      description,
      type_id,
      image,
      brand_id,
    } = product

    if (!name || name === "") {
      valid = false
      err.name = "Part number is required."
    }
    if (!categoryId || !categoryId.value) {
      valid = false
      err.categoryId = "Product category is required."
    }
    if (!subCategoryId || !subCategoryId.value) {
      valid = false
      err.subCategoryId = "Product subcategory is required."
    }
    if (!type_id || !type_id.value) {
      valid = false
      err.type_id = "Product type is required."
    }
    if (!brand_id || !brand_id.value) {
      valid = false
      err.brand_id = "Product brand is required."
    }
    if (!description || description === "") {
      valid = false
      err.description = "Product description is required."
    }
    if (!image || image === "") {
      valid = false
      err.image = "Product image is required."
    }
    // if (description && description.split(" ").length < 100) {
    //   valid = false
    //   err.description = "Product description should be atleast 100 words."
    // }
    await setError(err)
    return valid
  }

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <Input
              label="Part Number"
              name="name"
              value={product.name}
              error={error.name}
              required
              onChange={handleInput}
            />
          </div>
          <div className="col-md-6">
            <Select
              label="Brand"
              name="brand_id"
              isLoading={fetchingBrands}
              value={product.brand_id}
              options={brands}
              error={error.brand_id}
              required
              onChange={handleSelect}
            />
          </div>
          <div className="col-md-4">
            <Select
              label="Category"
              name="categoryId"
              isLoading={fetching}
              value={product.categoryId}
              options={categories}
              error={error.categoryId}
              required
              onChange={handleSelect}
            />
          </div>
          <div className="col-md-4">
            <Select
              label="Sub Category"
              name="subCategoryId"
              isLoading={fetching}
              value={product.subCategoryId}
              error={error.subCategoryId}
              required
              options={subcategories}
              onChange={handleSelect}
            />
          </div>
          <div className="col-md-4">
            <Select
              label="Product Type"
              name="type_id"
              isLoading={loading}
              value={product.type_id}
              error={error.type_id}
              required
              options={types}
              onChange={handleSelect}
            />
          </div>
          {/*<div className="col-md-6">*/}
          {/*  <Input*/}
          {/*    label="Tag"*/}
          {/*    name="tag"*/}
          {/*    placeholder="e.g Popular"*/}
          {/*    value={product.tag}*/}
          {/*    error={error.tag}*/}
          {/*    onChange={handleInput}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <TextArea
          label="Description"
          name="description"
          value={product.description}
          error={error.description}
          required
          onChange={handleInput}
        />
        <Input
          label="Image"
          name="image"
          type="file"
          // value={product.image}
          error={error.image}
          required
          onChange={handleFileInput}
        />
        <TextArea
          label="Cross Reference"
          name="crossReference"
          value={product.crossReference}
          error={error.crossReference}
          required
          placeholder="Add other part numbers seperated by a comma(,)"
          onChange={handleInput}
        />
        <TextArea
          label="Additional Info"
          name="additionalInfo"
          value={product.additionalInfo}
          error={error.additionalInfo}
          // required
          onChange={handleInput}
        />
      </div>
      <div className="card-footer d-flex justify-content-end gap-3">
        <Button
          text="Cancel"
          className="btn-secondary"
          onPress={() => handleClose()}
        />
        <Button
          text="Submit"
          className="btn-primary"
          isSubmitting={submittting}
          onPress={handleSubmit}
        />
      </div>
    </>
  )
}

export default Product
