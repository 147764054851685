import { useEffect, useState } from "react"
import { Table } from "../../components/Table"
import { Button } from "../../components/Button"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import axios from "axios"
import { Spinner } from "react-bootstrap"
import useApp from "../../hooks/useApp"
import { useHistory } from "react-router-dom"

const ViewUploads = () => {
  const { uploads } = useApp()
  const [data, setData] = useState(uploads)
  const [submitting, setSubmitting] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let arr = uploads.map((item) => {
      return {
        ...item,
        status: <span className="badge-not-submitted">Not Submitted</span>,
      }
    })
    setData(arr)
  }, [uploads])

  const handleClose = () => {
    history.push("../products")
  }

  const submit = async () => {
    setSubmitting(true)
    const { token } = await getStorage()
    for (let i = 0; i < data.length; i++) {
      let newUpload = { ...data[i] }
      newUpload.status = (
        <span className="badge-not-submitted">
          <Spinner size="sm" animation="border" variant="success" />
        </span>
      )
      setData((prev) => {
        const newData = [...prev]
        newData[i] = newUpload
        return newData
      })

      //let start submit
      const item = data[i]
      const options = {
        method: "POST",
        url: `${PRODUCTS_API}/products/upload`,
        headers: {
          authorization: "Bearer " + token,
        },
        data: {
          name: item["PartNumber"],
          description: item["Description(Min 100words)"],
          additionalInfo: item["Additional Info(optional)"],
          crossReference: item["CrossReference(optional & seperated by comma)"],
          brandCode: item["BrandCode"],
          typeCode: item["SubCategoryTypeCode"],
          price: item["Price"],
          quantity: item["Quantity"],
        },
      }
      await axios(options)
        .then((res) => {
          if (res) {
            newUpload.status = <span className="badge-success">Success</span>
            setData((prev) => {
              const dt = [...prev]
              dt[i] = newUpload
              return dt
            })
          } else {
            newUpload.status = <span className="badge-error">Failed</span>
            setData((prev) => {
              const dt = [...prev]
              dt[i] = newUpload
              return dt
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            newUpload.status = (
              <span className="badge-error">
                Failed, {err.response.data.message}
              </span>
            )
            setData((prev) => {
              const dt = [...prev]
              dt[i] = newUpload
              return dt
            })
          } else {
            newUpload.status = (
              <span className="badge-error">Error. Failed</span>
            )
            setData((prev) => {
              const dt = [...prev]
              dt[i] = newUpload
              return dt
            })
          }
        })
    }
    setSubmitting(false)
  }

  const headers = [
    { title: "Upload Status", key: "status", isBadge: true },
    { title: "Part Number", key: "PartNumber" },
    { title: "Brand Code", key: "BrandCode" },
    { title: "Sub Category Type Code", key: "SubCategoryTypeCode" },
    { title: "Description", key: "Description(Min 100words)" },
    { title: "Additional Info", key: "Additional Info(optional)" },
    {
      title: "Cross Reference",
      key: "CrossReference(optional & seperated by comma)",
    },
    { title: "Price", key: "Price" },
    { title: "Quantity", key: "Quantity" },
  ]

  return (
    <div className="card">
      <div className="card-body">
        <Table
          headers={headers}
          data={data}
          pagination={{}}
          showAdd
          addButtonText="Back to Products"
          addIcon="bx bx-left-arrow-alt"
          handleAddPressed={handleClose}
        />
      </div>
      <div className="card-footer d-flex gap-4 justify-content-end">
        <Button text="Close" className="btn-default" onPress={handleClose} />
        <Button
          text="Submit"
          isSubmitting={submitting}
          className="btn-primary"
          onPress={submit}
        />
      </div>
    </div>
  )
}

export default ViewUploads
