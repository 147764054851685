import { Input } from "../../components/Input"
import { Button } from "../../components/Button"
import { useEffect, useState } from "react"
import { PRODUCTS_API } from "../../constants/api"
import toastMessage from "../../utils/toastMessage"
import axios from "axios"
import { getStorage } from "../../utils/storage"

export default function StockUpdate({ selected, handleClose, getData }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [stock, setStock] = useState({
    price: "",
    quantity: "",
  })

  useEffect(() => {
    if (selected) {
      const { PRI_PRICE, PRI_STOCK_QUANTITY } = selected
      setStock({ price: PRI_PRICE, quantity: PRI_STOCK_QUANTITY })
    }
  }, [selected])

  function handleInput(e) {
    const { name, value } = e.target
    if (error[name]) {
      delete error[name]
    }
    setStock((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  async function handleUpdate(e) {
    e && e.preventDefault()
    const { token } = await getStorage()
    const { quantity, price } = stock
    const options = {
      url: `${PRODUCTS_API}/part/${selected && selected.PRI_ART_ID}`,
      method: "PUT",
      headers: {
        authorization: "Bearer " + token,
      },
      data: { quantity, price },
    }
    setLoading(true)

    await axios(options)
      .then((res) => {
        if (res.data) {
          toastMessage("success", "Stock updated successfully")
          getData()
          handleClose()
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toastMessage("error", err.response.data.message)
        } else {
          toastMessage("error", "Failed! Please try again.")
        }
      })
    setLoading(false)
  }

  return (
    <div className="card">
      <div className="card-body">
        <Input
          required
          label={"Price(KES)"}
          name={"price"}
          value={stock.price}
          error={error.price}
          onChange={handleInput}
        />
        <Input
          required
          label={"Stock Quantity"}
          name={"quantity"}
          value={stock.quantity}
          error={error.quantity}
          onChange={handleInput}
        />
      </div>

      <div className="card-footer d-flex gap-3 justify-content-end">
        <Button
          text={"Cancel"}
          className={"btn-secondary"}
          onPress={handleClose}
        />
        <Button
          text={"Update"}
          className={"btn-primary"}
          onPress={handleUpdate}
          isSubmitting={loading}
        />
      </div>
    </div>
  )
}
