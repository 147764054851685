export default function UnAuthorizedPage() {
  return (
    <div className="d-flex align-items-center flex-column gap-3 justify-content-center p-5 text-center">
      <h1 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
        Permission Denied!
      </h1>
      <p style={{ fontSize: "1.2rem" }}>
        You are not auhtorized to access this resource. Contact you admin to get
        permission.
      </p>
    </div>
  )
}
