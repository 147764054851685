import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { LoadingSpinner } from "../../components/LoadingSpinner"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"

const Stock = ({ product, handleClose, getData }) => {
  const defaultStock = {
    price: "",
    slashed_price: "",
    quantity: "",
  }
  const [stock, setStock] = useState(defaultStock)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(true)
  const [submittting, setSubmitting] = useState(false)

  useEffect(() => {
    if (product && product.id) {
      getStock()
    } else {
      setLoading(false)
    }
  }, [product])

  const getStock = async () => {
    const { token } = await getStorage()
    if (!product || !product.id) return
    setLoading(true)
    const options = {
      method: "GET",
      url: `${PRODUCTS_API}/stock/${product && product.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        if (res.data) {
          setStock(res.data)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    if (error[name]) {
      delete error[name]
    }

    setStock((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validateStock = async () => {
    let valid = true,
      err = {}
    const { price, slashed_price, quantity } = stock
    if (!price || price === "") {
      valid = false
      err.price = "Product price is required."
    }
    if (!quantity || quantity === "") {
      valid = false
      err.quantity = "Product quantity is required."
    }
    if (!slashed_price || slashed_price === "") {
      setStock((prev) => ({
        ...prev,
        slashed_price: 0,
      }))
    }
    await setError(err)
    return valid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let isValid = await validateStock()
    if (!isValid) return

    setSubmitting(true)
    let url = "/stock",
      method = "POST"
    if (stock.id) {
      delete stock.created_at
      delete stock.moq
      method = "PUT"
      url = `/stock/${stock.id}`
    } else {
      stock.product_id = product.id
    }
    const { token } = await getStorage()
    if (!stock.slashed_price) {
      stock.slashed_price = undefined
    }

    const options = {
      url: PRODUCTS_API + url,
      method,
      data: stock,
      headers: {
        authorization: "Bearer " + token,
      },
    }

    axios(options)
      .then((res) => {
        setSubmitting(false)
        if (res.data) {
          toastMessage(
            "success",
            `Stock ${stock.id ? "updated" : "added"} successfully`
          )
          if (getData) {
            getData()
          }
          handleClose()
        }
      })
      .catch((err) => {
        setSubmitting(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    )
  }
  return (
    <>
      <div className="card-body">
        <Input
          label="Price: "
          name="price"
          value={stock.price}
          required
          type="number"
          error={error.price}
          onChange={handleInput}
        />
        <Input
          label="Quantity: "
          name="quantity"
          value={stock.quantity}
          type="number"
          required
          error={error.quantity}
          onChange={handleInput}
        />
      </div>
      <div className="card-footer d-flex justify-content-end gap-3">
        <Button
          text="Cancel"
          className="btn-secondary"
          onPress={() => handleClose()}
        />
        <Button
          text="Submit"
          disabled={!product}
          className="btn-primary"
          isSubmitting={submittting}
          onPress={handleSubmit}
        />
      </div>
    </>
  )
}

export default Stock
