import { Tabs } from "../components/Tabs"
import { Processed, UnProcessed } from "../features/Sales"
import { useState } from "react"

const SalesPage = () => {
  const [refresh, triggerRefresh] = useState(false)
  const renderUnprocessedSales = () => {
    return <UnProcessed triggerRefresh={triggerRefresh} />
  }

  const renderProcessedSales = () => {
    return <Processed refresh={refresh} />
  }
  const options = [
    {
      title: "Unprocessed Invoices",
      data: renderUnprocessedSales(),
    },
    {
      title: "Processed Invoices",
      data: renderProcessedSales(),
    },
  ]
  return <Tabs options={options} />
}

export default SalesPage
