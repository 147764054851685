import React, { useState } from "react"
import { useEffect } from "react"
import { DeleteModal, Modal } from "../components/Modal"
import { Table } from "../components/Table"
import { CLIENTS_API } from "../constants/api"
// import { clients } from '../constants/clients'
import ViewClient from "../features/Clients/ViewClient"
import { getStorage } from "../utils/storage"
import toastMessage from "../utils/toastMessage"
import axios from "axios"
import useApp from "../hooks/useApp"

const ClientsPage = () => {
  const [pagination, setPagination] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [selected, setSelected] = useState(null)
  const [edit, setEdit] = useState(false)
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState("")
  const { permissions } = useApp()

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      getSearch()
    }, 1000)
    return () => clearTimeout(getData)
  }, [search])

  async function getClients(params) {
    const { permissions = [], token, id } = await getStorage()
    setLoading(true)

    let perms = []
    if (permissions) {
      perms = permissions.map((perm) => perm.value)
    }

    let url = CLIENTS_API
    if (perms.includes("view-added-clients")) {
      url += `/clients-by-user?userId=${id}`
    } else if (perms.includes("view-clients")) {
      url += "/clients"
    } else {
      setLoading(false)
      toastMessage("error", "Permissions denied")
      window.location.href = "/login"
    }

    const options = {
      method: "GET",
      url,
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        console.log(res.data)
        if (res.data) {
          const { pagination, results } = res.data
          const data = results.map((item) => ({
            ...item,
            createdBy: `${item.createdByFirstName} ${item.createdByLastName}`,
          }))
          setClients(data)
          setPagination(pagination)
        }
      })
      .catch((err) => {
        console.log({ err })
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handleView = (client) => {
    setViewModal(true)
    setSelected(client)
    setEdit(false)
  }

  const handleEdit = (client) => {
    setViewModal(true)
    setSelected(client)
    setEdit(true)
  }

  const handleAdd = () => {
    setViewModal(true)
    setSelected(null)
    setEdit(false)
  }

  const handleCloseModal = () => {
    setViewModal(false)
    setDeleteModal(false)
    setSelected(null)
    setEdit(false)
  }

  const handleDelete = (client) => {
    setSelected(client)
    setDeleteModal(true)
  }

  const handlePagination = (page) => {
    if (!page) return
    getClients({ pageNumber: page })
  }

  function getSearch() {
    if (search === "") return
    return getClients({ search })
  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    if (value === "") {
      return getClients()
    }
  }

  const onDelete = async () => {
    const { token } = await getStorage()
    setDeleting(true)

    const options = {
      method: "DELETE",
      url: CLIENTS_API + "/client/" + selected.id,
      headers: {
        authorization: "Bearer " + token,
      },
    }

    axios(options)
      .then((res) => {
        setDeleting(false)
        if (res.status === 200) {
          toastMessage("success", "Client deleted successfully")
          handleCloseModal()
          getClients()
        }
      })
      .catch((err) => {
        setDeleting(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const headers = [
    { title: "First Name", key: "firstName" },
    { title: "Last Name", key: "lastName" },
    { title: "Company Name", key: "companyName" },
    { title: "Address", key: "address" },
    { title: "Location", key: "location" },
    { title: "Email", key: "email" },
    { title: "Phone", key: "phone" },
    { title: "Created By", key: "createdBy" },
    {
      title: "Date of Registration",
      key: "createdAt",
      isMoment: true,
      formatTime: "lll",
    },
  ]
  if (permissions && permissions.includes("manage-clients")) {
    headers.push({ title: "Action", key: "action" })
  }
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Table
            isLoading={loading}
            data={clients}
            showAdd={permissions && permissions.includes("manage-clients")}
            pagination={pagination}
            handlePagination={handlePagination}
            addButtonText="Add Client"
            search_text={search}
            handleSearch={handleSearch}
            handleAddPressed={handleAdd}
            placeholder={"Search by name or email or phone"}
            headers={headers}
            rowPress={(row) => handleView(row)}
            actions={[
              {
                name: "View",
                onPress: (row) => handleView(row),
              },
              {
                name: "Edit",
                onPress: (row) => handleEdit(row),
              },
              // {
              //   name: "Delete",
              //   onPress: (row)=> handleDelete(row),
              // },
            ]}
          />
        </div>
      </div>

      <Modal
        show={viewModal}
        title={"Client Management"} //+ selected ? `: ${selected.firstName} ${selected.lastName}`: ''}
        handleClose={handleCloseModal}
        size="lg"
      >
        <ViewClient
          edit={edit}
          client={selected}
          setSelected={setSelected}
          getClients={getClients}
          handleClose={handleCloseModal}
        />
      </Modal>

      <Modal
        show={deleteModal}
        title={"Delete Client"}
        handleClose={handleCloseModal}
      >
        <DeleteModal
          name={selected && selected.firstName + " " + selected.lastName}
          handleClose={handleCloseModal}
          isSubmitting={deleting}
          onDelete={onDelete}
        />
      </Modal>
    </div>
  )
}

export default ClientsPage
